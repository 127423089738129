export default {
  routes: {
    butler: require('@/module/butler/routes').default,
    'master-data': require('@/module/master-data/routes').default,
    'plugin-butler-permission-manager': require('@/module/plugin-butler-permission-manager/routes').default,
    // 'plugin-md-category': require('@/module/plugin-md-category/routes').default,
    'plugin-md-essential': require('@/module/plugin-md-essential/routes').default,
    // 'plugin-md-migration': require('@/module/plugin-md-migration/routes').default,
    // 'plugin-md-product-order': require('@/module/plugin-md-product-order/routes').default,
    // 'plugin-md-product-structure': require('@/module/plugin-md-product-structure/routes').default,
    // 'plugin-md-structure': require('@/module/plugin-md-structure/routes').default,
    'plugin-attendance': require('@/module/plugin-attendance/routes').default
  },
  stores: {
    'master-data': require('@/module/master-data/store').default
    // 'plugin-md-category': require('@/module/plugin-md-category/store').default
  },
  hooks: {
    butler: require('@/module/butler/hook').default,
    'master-data': require('@/module/master-data/hook').default,
    'plugin-attendance': require('@/module/plugin-attendance/hook').default
    // 'plugin-md-essential': require('@/module/plugin-md-essential/hook').default,
    // 'plugin-md-product-order': require('@/module/plugin-md-product-order/hook').default,
    // 'plugin-md-product-structure': require('@/module/plugin-md-product-structure/hook').default,
    // 'plugin-md-structure': require('@/module/plugin-md-structure/hook').default
  }
}
